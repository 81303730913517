import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { signInWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../../service/firebaseConnection';
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async() => {
    if(email === '' || password === ''){
      toast.warning('Informe todos os campos!');
      return;
    }
  
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("login sucesso");
      window.location = "/Imoveis";
      toast.success('Login realizado com sucesso!');
    } catch (error) {
      toast.error('Erro ao fazer login: ' + error.message);
    }
  };

  return (
    <Container
      maxWidth="xs"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: 'black'
      }}
    >
      <Box
        sx={{
          width: '100%',
          padding: '2rem',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Bem-vindo
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          Por favor, faça login para continuar
        </Typography>

        {/* Formulário de Login */}
        <div style={{ width: '100%', marginTop: '1rem' }}>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              style: { fontSize: '16px' },
            }}
            InputLabelProps={{
              style: { fontSize: '16px' },
            }}
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              style: { fontSize: '16px' },
            }}
            InputLabelProps={{
              style: { fontSize: '16px' },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{
              marginTop: '1.5rem',
              padding: '10px 0',
              fontSize: '16px',
            }}
            onClick={() => handleLogin()}
          >
            Entrar
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => (window.location = "/Imoveis")}
            fullWidth
            style={{
              marginTop: '1.5rem',
              padding: '10px 0',
              fontSize: '16px',
            }}
          >
            Cancelar
          </Button>
        </div>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
};

export default Login;

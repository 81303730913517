import logo from "./logo.svg";
import "./App.css";
import Home from "././Pages/Home/home";
import Imoveis from "././Pages/Imoveis/imoveis";
import { BrowserRouter } from "react-router-dom";
import RoutesApp from "./Routes";

function App() {
  return (
    <BrowserRouter>
      <RoutesApp />
    </BrowserRouter>
  );
}

export default App;

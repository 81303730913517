import "./home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importa os estilos do Carousel
import { Carousel } from "react-responsive-carousel";
import Button from "@mui/material/Button";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Logo from "../../assets/img/logo.png";
import Avatar from '../../assets/img/rodrigo.jpg';

const Home = () => {
  return (
    <div className="body">
      <div className="header">
        <div className="header-top">
          <div className="container-carousel">
            <Carousel
              className="carousel"
              showThumbs={false} // Remove miniaturas
              showStatus={false} // Remove o contador de status
              infiniteLoop={true} // Ativa loop infinito
              autoPlay={true} // Ativa autoplay
              interval={3000} // Tempo entre transições (em ms)
              transitionTime={500} // Velocidade da transição
              dynamicHeight={false} // Altura fixa
              stopOnHover={true} // Pausa ao passar o mouse
            >
              <div>
                <img
                  src="https://tangaradaserra.mt.gov.br/wp-content/uploads/2024/03/tangara-capa.jpg"
                />
              </div>
              <div>
                <img
                  src="https://tangaradaserra.mt.gov.br/wp-content/uploads/2024/03/043629feb72cb874b0fe701975d8b58e.jpeg"
                />
              </div>
              <div>
                <img
                  src="https://tangaradaserra.nucleo.site/wp-content/uploads/2023/05/9229b9e91682036efe32c81b96a50573.jpeg"
                />
              </div>
            </Carousel>
          </div>
          <div className="container-banner">
            <div className="title-banner">
              <img className="logo-home" src={Logo} alt="Logo" />
            </div>
          </div>
        </div>
        <div className="button-show">
            <Button
              onClick={() => (window.location = "/Imoveis")}
              variant="contained"
              color="info"
            >
              Ver imóveis
            </Button>
          </div>
        <FloatingWhatsApp
          chatMessage="Olá, como podemos ajudar?"
          darkMode
          allowEsc
          avatar={Avatar}
          accountName="Rodrigo Carpenedo imóveis"
          phoneNumber="65999676622"
        />
      </div>
    </div>
  );
};

export default Home;

import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';


const firebaseConfig = {
    apiKey: "AIzaSyATPM1z0SN0oaXeT-bjeKjzW41y2g6a3rY",
    authDomain: "rodrigo-carpenedo-imovei-2c67b.firebaseapp.com",
    projectId: "rodrigo-carpenedo-imovei-2c67b",
    storageBucket: "rodrigo-carpenedo-imovei-2c67b.appspot.com",
    messagingSenderId: "16688933286",
    appId: "1:16688933286:web:b3d4d8275aaad96e234767"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export {auth, db, storage};
import '../Footer/footer.css';

const Footer = () => {
    return (
        <footer class="footer">
            <div class="footer-container">
                <div class="footer-section">
                    <h3>Sobre Nós</h3>
                    <p>
                        Olá, sou Rodrigo Carpenedo, corretor de imóveis, com CRECI 15163 e CNAI 42224. Com anos de experiência no mercado, minha missão é ajudar você a encontrar o imóvel perfeito, seja para morar, investir ou realizar negócios.
                    </p>
                </div>
                <div class="footer-section">
                    <h3>Contato</h3>
                    <p>Email: contato@rcnegociosimobiliarios.com</p>
                    <p>Telefone: (65)9-9967-6622</p>
                </div>
            </div>
            <div class="footer-bottom">
                <p>CRECI-MT 15163 / CNAI 42224</p>
                <p>&copy; 2024 RC Negócios Imobiliários - Todos os direitos reservados</p>
            </div>
        </footer>
    )
}

export default Footer;
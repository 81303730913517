import styled from "styled-components";

export const Container = styled.div`
  width: 32.5%;
  margin-bottom: 12px;
  background-color: var(--white);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  @media (max-width: 480px) {
    min-width: 100%;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    min-width: 49%;
  }
`;

export const Img = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
`;

export const Description = styled.div`
  padding: 20px;

  a {
    color: var(--black);
  }

  h4 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 10px;
  }

  img {
    width: 20px;
  }

  div.arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div.arrow img {
    align-self: center;
    height: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  span {
    margin-bottom: 10px;
  }

  img {
    width: 15px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px 150px;

  @media (max-width: 480px) {
    padding: 25px 20px;
    justify-content: center;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    padding: 25px 100px;
  }
`;

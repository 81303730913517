import React from 'react';
import Logo from '../../../assets/img/logo.png';
import './Navbar.css';
import "react-toastify/dist/ReactToastify.css";

function Navbar() {
  return (
    <div className="header">
      <div className="header-top">
        <div className="container">
          <div className="container-logo-nav">
            <img className="logo-header-nav" alt="Logo" src={Logo} />
          </div>
          <nav className="navbar">
            <ul className="navbar-list">
              <li>
                <a href="/" className="navbar-link" data-nav-link>
                  Inicio
                </a>
              </li>

              <li>
                <a href="/Sobre" className="navbar-link" data-nav-link>
                  Sobre
                </a>
              </li>

              <li>
                <a href="/Imoveis" className="navbar-link" data-nav-link>
                  Imóveis
                </a>
              </li>

              <li>
                <a href="/Contato" className="navbar-link" data-nav-link>
                  Contato
                </a>
              </li>
              <li>
                <a href="/Avaliacao" className="navbar-link" data-nav-link>
                  Avaliação de imóveis
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Navbar from '../Components/Navbar/navbar';
import Footer from '../Components/Footer/Footer';
import Avatar from '../../assets/img/rodrigo.jpg';

const Contato = () => {

  const [nome, setNome] = useState("");
  const [assunto, setAssunto] = useState("");

  return (
    <div className="body">
      <Navbar />
      <Container maxWidth="md">
        <TextField
          id="nome"
          label="Nome"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setNome(e.target.value)}
        />
        <TextField
          id="nome"
          label="Assunto"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setAssunto(e.target.value)}
        />
        <div className="container-buttons-modal" style={{ gridColumn: 'span 2', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <Button
            style={{ margin: '0 15px' }}
            variant="contained"
            color="success"
            target="_blank"
            disabled={!nome || !assunto}
            href={`https://api.whatsapp.com/send?phone=5565999676622&text=Olá, me chamo ${nome} gostaria de conversar sobre ${assunto}`}
          >
            Entrar em contato
          </Button>
        </div>
      </Container>
      <FloatingWhatsApp avatar={Avatar} chatMessage="Olá, como podemos ajudar?" darkMode allowEsc accountName="Rodrigo Carpenedo imóveis" phoneNumber="65996875543" />
      <Footer />
    </div>
  );
};

export default Contato;

import "./imoveis.css";
import 'swiper/css';
import { Container, Description, Img } from "./styles";
import { Wrapper } from "./styles";
import { useState, useEffect, useCallback } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importar estilos do carrossel
import { Carousel } from 'react-responsive-carousel';
import { collection, addDoc, deleteDoc, query, where, getDocs, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Badge from "@mui/material/Badge";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Form from 'react-bootstrap/Form';
import MenuItem from '@mui/material/MenuItem';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from '../../service/firebaseConnection';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'
import Navbar from '../Components/Navbar/navbar';
import { v4 as uuidv4 } from 'uuid'; // Se você estiver usando UUID
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HotelIcon from '@mui/icons-material/Hotel';
import ShowerIcon from '@mui/icons-material/Shower';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import Footer from '../Components/Footer/Footer';
import Avatar from '../../assets/img/rodrigo.jpg';

const Home = () => {

  const propertiesTypes = [
    { id: 1, nome: "Casa" },
    { id: 2, nome: "Apartamento" },
    { id: 3, nome: "Barracão" },
    { id: 4, nome: "Terreno" },
    { id: 5, nome: "Sala comercial" },
    { id: 6, nome: "Sitio" },
    { id: 7, nome: "Kitnet" },
    { id: 8, nome: "Chacara" },
    { id: 9, nome: "Fazenda" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [data, setData] = useState([]);
  const [modalView, setModalView] = useState(false);
  const [id, setId] = useState("");
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cidade, setCidade] = useState("");
  const [cities, setCities] = useState([]);
  const [cep, setCep] = useState("");
  const [tipo, setTipo] = useState("");
  const [area, setArea] = useState("");
  const [quartos, setQuartos] = useState("");
  const [banheiros, setBanheiros] = useState("");
  const [garagens, setGaragens] = useState("");
  const [valor, setValor] = useState("");
  const [disponivel, setDisponivel] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [municipioSelecionado, setMunicipioSelecionado] = useState("");
  const [filterTextTipo, setFilterTextTipo] = useState("");
  const [filterTextCity, setFilterTextCity] = useState("");
  const [filterTextPrice, setFilterTextPrice] = useState("");
  const [filterTextId, setFilterTextId] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [showModalViewProperties, setShowModalViewProperties] = useState(false);
  const [selectedPropertie, setSelectedPropertie] = useState([]);
  const [selectedPropertieId, setSelectedPropertieId] = useState("");
  const [newCity, setNewCity] = useState([]);
  const [imgsModal, setImgsModal] = useState();
  const [canEdit, setCanEdit] = useState(false);
  const endpointBucket = process.env.REACT_APP_BUCKET_ENDPOINT;
  const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_SECRET_ACCESS;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location = "/Imoveis";
      toast.success('Sessão finalizada');
    } catch (error) {
      toast.error('Erro ao finalizar sessão: ' + error.message);
    }
  };

  const clearFields = useCallback(() => {
    setTitulo('');
    setDescricao('');
    setEndereco('');
    setCidade('');
    setCep('');
    setTipo('');
    setArea('');
    setQuartos('');
    setBanheiros('');
    setGaragens('');
    setValor('');
    setDisponivel('');
  })

  const s3Client = new S3Client({
    region: "us-east-1",
    endpoint: endpointBucket,
    credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    },
  });

  const viewPropertie = useCallback((item) => {
    setSelectedPropertie(item);
    setSelectedPropertieId(item.id);
    setShowModalViewProperties(true);
    setImgsModal(item.imgUrls);
  });

  useEffect(() => {
    const getData = async () => {
      await getDocs(collection(db, 'properties'))
        .then((cliente) => {
          let clienteData = cliente.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(clienteData);
          toast.success("Dados carregados com sucesso!");
        })
        .catch((err) => {
          toast.warning("Ops, houve um problema ao carregar as informações");
          console.log(err);
        });
    };
    const getCities = async () => {
      await getDocs(collection(db, "cities"))
        .then((cliente) => {
          let clienteData = cliente.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCities(clienteData);
        }).catch((err) => {
          toast.warning("Ops, houve um problema ao carregar as informações, verifique os logs. ");
          console.log(err);
        })
    }
    getCities();
    getData();
  }, [db]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      selectedFiles.length === 0 ||
      titulo === "" ||
      descricao === "" ||
      endereco === "" ||
      quartos === "" ||
      garagens === "" ||
      valor === "" ||
      cidade === "" ||
      cep === "" ||
      tipo === "" ||
      disponivel === "" ||
      area === "" ||
      banheiros === ""
    ) {
      toast.error("Por favor, preencha todos os campos!");
      return;
    }

    try {
      const uploadPromises = Array.from(selectedFiles).map(async (file) => {
        const uploadParams = {
          Bucket: 'rodrigo-carpenedo-imoveis',
          Key: file.name,
          Body: file,
          ContentType: file.type,
        };

        const command = new PutObjectCommand(uploadParams);
        const response = await s3Client.send(command, {
          mode: 'no-cors'
        });
        console.log(`Arquivo ${file.name} enviado com sucesso:`, response);

        return `https://pub-8317e9c8f366474496cee204e4f982fc.r2.dev/rodrigo-carpenedo-imoveis/${uploadParams.Key}`;
      });

      const generatedId = uuidv4(); // ou use sua lógica de ID
      const imgUrls = await Promise.all(uploadPromises);
      toast.success("Imagens enviadas com sucesso!");

      const docRef = await addDoc(collection(db, "properties"), {
        id: generatedId,
        titulo: titulo,
        descricao: descricao,
        endereco: endereco,
        cidade: cidade,
        cep: cep,
        tipo: tipo,
        area: area,
        quartos: quartos,
        banheiros: banheiros,
        garagens: garagens,
        valor: valor,
        disponivel: disponivel,
        imgUrls: imgUrls,
      });
      console.log("Documento adicionado com ID: ", generatedId);
      setId(generatedId);
      toast("Propriedade adicionada com sucesso!");

      closeModal();
    } catch (error) {
      console.error("Erro ao adicionar propriedade: ", error);
      toast.error("Erro ao adicionar propriedade: " + error.message);
    }
  };

  const handleAddCity = async () => {
    const generatedId = uuidv4();
    try {
      const docRef = await addDoc(collection(db, "cities"), {
        id: generatedId,
        name: newCity,
      });
      toast.success("Cidade adicionada!");
    } catch (error) {
      console.error("Erro ao adicionar cidade: ", error);
      toast.error("Erro ao adicionar cidade: ", error);
    }
  }

  const handleUpdateDoc = async (idProcurado) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Essa ação não poderá ser desfeita!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, salvar!",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const collectionRef = collection(db, "properties");
          const q = query(collectionRef, where("id", "==", idProcurado));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach(async (docSnap) => {
              const docRef = docSnap.ref;
              const originalData = docSnap.data(); // Obter dados originais do documento

              // Objeto com os novos valores
              const novosDados = {
                titulo,
                descricao,
                endereco,
                cidade,
                cep,
                tipo,
                area,
                quartos,
                banheiros,
                garagens,
                valor,
                disponivel,
              };

              // Criar um objeto somente com os campos que mudaram
              const camposModificados = {};
              for (const key in novosDados) {
                if (novosDados[key] !== originalData[key]) {
                  camposModificados[key] = novosDados[key];
                }
              }

              // Verificar se há campos para atualizar
              if (Object.keys(camposModificados).length > 0) {
                await updateDoc(docRef, camposModificados);
                toast.success("Propriedade atualizada com sucesso!");
                console.log("Propriedade atualizada com sucesso!");
                closeModal();
              } else {
                console.log("Nenhum campo foi alterado.");
              }
            });
          } else {
            console.log("Nenhum documento encontrado com o ID especificado.");
          }
        } catch (error) {
          console.error("Erro ao atualizar propriedade: ", error);
        }
      }
    })
  };

  const handleDeletePropertie = async (itemId) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Essa ação não poderá ser desfeita!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log("Procurando documento com o campo 'id' igual a:", itemId);

          // Cria uma consulta para buscar o documento com o campo "is" igual ao itemId
          const propertiesRef = collection(db, "properties");
          const q = query(propertiesRef, where("id", "==", itemId));
          const querySnapshot = await getDocs(q);

          // Verifica se o documento foi encontrado e o exclui
          if (!querySnapshot.empty) {
            querySnapshot.forEach(async (docSnapshot) => {
              await deleteDoc(docSnapshot.ref);
              console.log("Documento excluído com sucesso.");
              toast.success("Imóvel excluído com sucesso!");
              closeModal();
            });
          } else {
            console.log("Nenhum documento encontrado com o campo 'is' igual a:", itemId);
            toast.error("Imóvel não encontrado.");
          }
        } catch (error) {
          console.error("Erro ao excluir imóvel: ", error);
          toast.error("Erro ao excluir imóvel: " + error.message);
        }
      }
    });
  };

  const handleDeleteCity = async (itemId) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Antes de excluir verifique se a cidade não está em uso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const cityRef = collection(db, "cities");
          const q = query(cityRef, where("id", '==', itemId));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach(async (docSnapshot) => {
              await deleteDoc(docSnapshot.ref);
              console.log("Documento excluído com sucesso.");
              toast.success("Cidade excluída com sucesso!");
              closeModal();
            });
          } else {
            console.log("Nenhum documento encontrado com o campo 'id' igual a:", itemId);
            toast.error("Cidade não encontrada.");
          }

        } catch (error) {
          console.error("Erro ao excluir cidade: ", error);
          toast.error("Erro ao excluir cidade: " + error.message);
        }
      }
    })
  }

  const filteredData = data.filter((item) => {
    const matchesCity = filterTextCity === "" || item.cidade === filterTextCity;
    const matchesType = filterTextTipo === "" || item.tipo === filterTextTipo;
    const matchesPrice = filterTextPrice === "" || parseInt(item.valor) <= parseInt(filterTextPrice);
    const matchesId = filterTextId === "" || item.id === filterTextId;
    return matchesCity && matchesType && matchesPrice && matchesId;
    // return matchesType && matchesPrice;
  });

  const closeModal = () => {
    window.location = "/Imoveis";
    setModalView(false);
  };

  useEffect(() => {
    if (selectedPropertie) {
      setTitulo(selectedPropertie.titulo || "");
      setDescricao(selectedPropertie.descricao || "");
      setEndereco(selectedPropertie.endereco || "");
      setCidade(selectedPropertie.cidade || "");
      setCep(selectedPropertie.cep || "");
      setTipo(selectedPropertie.tipo || "");
      setArea(selectedPropertie.area || "");
      setQuartos(selectedPropertie.quartos || "");
      setBanheiros(selectedPropertie.banheiros || "");
      setGaragens(selectedPropertie.garagens || "");
      setValor(selectedPropertie.valor || "");
      setDisponivel(selectedPropertie.disponivel !== undefined ? selectedPropertie.disponivel : true);
    }
  }, [selectedPropertie]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setIsAuthenticated(true);
        setUser(currentUser);
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [data]);

  return (
    <div className="body">
      <Navbar />
      <div className="container-menu">
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ color: "white" }}
          className='navbar-link'
          endIcon={<KeyboardArrowDownIcon />}
        >
          Menu
        </Button>
        <Menu
          id="fade-menu"
          className="menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {isAuthenticated ? (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() => { clearFields(); setModalView(true) }}
              >
                Adicionar Imóvel
              </Button>
              <Button
                onClick={() => handleLogout()}
                variant="contained"
                color="warning"
              >
                Encerrar sessão
              </Button>
              {
                isAuthenticated ? (
                  <>
                    <TextField
                      id="new-city"
                      label="Adicionar nova cidade"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setNewCity(e.target.value)}
                    />
                    <Button type="button" variant="contained" disabled={!newCity} onClick={handleAddCity} style={{ marginTop: '20px', marginLeft: '30%', width: '200px' }}>
                      Salvar
                    </Button></>
                ) : (
                  <></>
                )
              }
            </>)
            :

            <MenuItem
              color="success"
              variant="contained"
              onClick={() => (window.location = "/Login")}
            >
              Login
            </MenuItem>}
        </Menu>
      </div>
      <div className="container-filters">
        <div className="container-filter-cidade">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">CIDADE</InputLabel>
            <Select
              onChange={(e) => setFilterTextCity(e.target.value)}
            >
              {cities.map((municipio) => (
                <>
                  <MenuItem key={municipio.id} value={municipio.id}>{municipio.name}
                    {
                      isAuthenticated ? (
                        <Button onClick={() => handleDeleteCity(municipio.id)} color="error">
                          <DeleteIcon color="red" />
                        </Button>
                      ) : (
                        <></>
                      )
                    }
                  </MenuItem></>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Filtro por tipo */}
        <div className="container-filter-tipo">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label-Type">TIPO</InputLabel>
            <Select
              onChange={(e) => setFilterTextTipo(e.target.value)}
            >
              {propertiesTypes.map((item) => (
                <MenuItem key={item.id} value={item.nome}>{item.nome}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Filtro por pretensão */}
        <div className="container-filter-pretensao">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label-Pretensaos">PRETENSÃO</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Pretensão"
            >
              <MenuItem value="Aluguel">Aluguel</MenuItem>
              <MenuItem value="Compra">Compra</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* Filtro por preço */}
        <div className="container-filter-preço">
          <FormControl fullWidth>
            <TextField
              id="input-valor"
              size="medium"
              label="VALOR MÁXIMO"
              variant="outlined"
              type="text"
              onChange={(e) => setFilterTextPrice(e.target.value)}
            />
          </FormControl>
        </div>

        <div className="container-filter-codigo">
          <FormControl fullWidth>
            <TextField
              id="input-codigo"
              size="medium"
              label="CÓDIGO"
              variant="outlined"
              type="text"
              onChange={(e) => setFilterTextId(e.target.value)}
            />
          </FormControl>
        </div>
      </div>

      <Wrapper>
        {
          filteredData.length >= 1 ? filteredData.map((item) => {
            const imgs = item.imgUrls;
            return (
              <Container>
                <Tooltip title="Ver imagem">
                  <Img>
                    <a href="#" onClick={() => viewPropertie(item)}><img alt="Ver imagem" src={imgs[0]} /></a>
                  </Img>
                </Tooltip>
                <Description>
                  <h4>{item.titulo}</h4>
                  <div className="arrow">
                    <span>{item.descricao}</span>
                    <span>Código: <b>{item.id.substr(0, 8)}</b></span>
                  </div>
                </Description>
                
                  <div>
                    <ol>
                      <li><CheckIcon /> Disponível: {item.disponivel ? " Sim" : " Não"}</li>
                      <li><AttachMoneyIcon />{` ${item.valor},00`}</li>
                      <li><HotelIcon />{` Quartos: ${item.quartos}`}</li>
                      <li><ShowerIcon />{` Banheiros: ${item.banheiros}`}</li>
                    </ol>
                  </div>
                {
                  isAuthenticated ? (
                    <></>
                  ) : (<></>)
                }
              </Container>
            );
          }) :
            <div style={{ width: '100%' }}>
              <p>Carregando imóveis, aguarde...</p>
              <Stack direction="row" spacing={1} className="container-skeleton">
                <Skeleton variant="rectangular" width="35%" height={300} />
                <Skeleton variant="rectangular" width="35%" height={300} />
                <Skeleton variant="rectangular" width="35%" height={300} />
              </Stack>
            </div>
        }

      </Wrapper>
      <Modal id="modal-new-propertie" className="modal-add" show={modalView} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Imóvel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFileMultiple" multiple className="mb-3">
              <input type="file" multiple onChange={(e) => setSelectedFiles(e.target.files)} />
            </Form.Group>
            <TextField
              id="titulo"
              label="Título"
              variant="outlined"
              fullWidth
              margin="normal"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />

            <TextField
              id="descricao"
              label="Descrição"
              variant="outlined"
              fullWidth
              margin="normal"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />

            <TextField
              id="endereco"
              label="Endereço"
              variant="outlined"
              fullWidth
              margin="normal"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Cidade</InputLabel>
              <Select
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
              >
                {
                  cities.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <TextField
              id="cep"
              label="CEP"
              variant="outlined"
              fullWidth
              margin="normal"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Tipo</InputLabel>
              <Select
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
              >
                {propertiesTypes.map((type) => (
                  <MenuItem key={type.id} value={type.nome}>
                    {type.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="area"
              label="Área"
              variant="outlined"
              fullWidth
              margin="normal"
              value={area}
              onChange={(e) => setArea(e.target.value)}
            />

            <TextField
              id="quartos"
              label="Quartos"
              variant="outlined"
              fullWidth
              margin="normal"
              value={quartos}
              onChange={(e) => setQuartos(e.target.value)}
            />

            <TextField
              id="banheiros"
              label="Banheiros"
              variant="outlined"
              fullWidth
              margin="normal"
              value={banheiros}
              onChange={(e) => setBanheiros(e.target.value)}
            />

            <TextField
              id="garagens"
              label="Garagens"
              variant="outlined"
              fullWidth
              margin="normal"
              value={garagens}
              onChange={(e) => setGaragens(e.target.value)}
            />

            <TextField
              id="valor"
              label="Valor"
              variant="outlined"
              fullWidth
              margin="normal"
              value={valor}
              onChange={(e) => setValor(e.target.value)}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Disponível</InputLabel>
              <Select
                value={disponivel ? 'Sim' : 'Não'}
                onChange={(e) => setDisponivel(e.target.value)}
              >
                <MenuItem value="Sim">Sim</MenuItem>
                <MenuItem value="Não">Não</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" type="submit">Salvar</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal size="xl" id="view-propertie" show={showModalViewProperties}>
        <Modal.Body>
          <Card style={{ padding: '30px' }}>
            <div className="carousel-container">
              {imgsModal && imgsModal.length > 0 ? (
                <Carousel className="carousel" showThumbs={false} dynamicHeight={false} infiniteLoop={true} useKeyboardArrows={true} emulateTouch={true}>
                  {imgsModal.map((item, index) => (
                    <div key={index}>
                      <img src={item} alt={`Imagem ${index + 1}`} />
                    </div>
                  ))}
                </Carousel>
              ) : (
                <p>Nenhuma imagem disponível</p>
              )}
            </div>
            <div className="container-description">
              <div style={{ gridColumn: 'span 2', textAlign: 'center', marginBottom: '16px' }}>
                <h2>Detalhes do Imóvel</h2>
              </div>

              <InputLabel>Titulo</InputLabel>
              <TextField
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                size="small"
                disabled={!canEdit}
              />

              <InputLabel className="label-description">Descrição</InputLabel>
              <TextField
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                size="small"
                multiline
                disabled={!canEdit}
              />

              <InputLabel className="label-description">Valor</InputLabel>
              <TextField
                value={valor}
                onChange={(e) => setValor(e.target.value)}
                size="small"
                disabled={!canEdit}
              />

              <InputLabel className="label-description">Banheiros</InputLabel>
              <TextField
                value={banheiros}
                onChange={(e) => setBanheiros(e.target.value)}
                size="small"
                disabled={!canEdit}
              />

              <InputLabel className="label-description">CEP</InputLabel>
              <TextField
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                size="small"
                disabled={!canEdit}
              />

              <InputLabel className="label-description">Cidade</InputLabel>
              <Select
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
                disabled={!canEdit}
              >
                {
                  cities.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>

              <InputLabel className="label-description">Disponível?</InputLabel>
              <Select
                value={disponivel ? 'Sim' : 'Não'}
                onChange={(e) => setDisponivel(e.target.value)}
                disabled={!canEdit}
              >
                <MenuItem value="Sim">Sim</MenuItem>
                <MenuItem value="Não">Não</MenuItem>
              </Select>

              <InputLabel className="label-description">Endereço</InputLabel>
              <TextField
                value={endereco}
                onChange={(e) => setEndereco(e.target.value)}
                size="small"
                disabled={!canEdit}
              />

              <InputLabel className="label-description">Garagem</InputLabel>
              <TextField
                value={garagens}
                onChange={(e) => setGaragens(e.target.value)}
                size="small"
                disabled={!canEdit}
              />

              <InputLabel className="label-description">Quartos</InputLabel>
              <TextField
                value={quartos}
                onChange={(e) => setQuartos(e.target.value)}
                size="small"
                disabled={!canEdit}
              />

              <InputLabel className="label-description">Tipo</InputLabel>
              <TextField
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                size="small"
                disabled={!canEdit}
              />

              <div className="container-buttons-modal" style={{ gridColumn: 'span 2', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                {
                  !isAuthenticated ? (
                    <Button
                      style={{ margin: '0 15px' }}
                      variant="contained"
                      color="success"
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=5565999676622&text=Olá, gostaria de mais informações sobre o imóvel: ${selectedPropertie.id}`}
                    >
                      Entrar em contato
                    </Button>
                  ) : (<></>)
                }
                <Button
                  style={{ margin: '0 15px' }}
                  variant="contained"
                  color="warning"
                  onClick={() => setShowModalViewProperties(false)}
                >
                  Cancelar
                </Button>
                {
                  isAuthenticated ? (
                    <>
                      <Button
                        style={{ margin: '0 15px' }}
                        variant="contained"
                        color="error"
                        onClick={() => handleDeletePropertie(selectedPropertie.id)}
                      >
                        Excluir imóvel
                      </Button>
                      <Button
                        style={{ margin: '0 15px' }}
                        variant="contained"
                        color="primary"
                        onClick={() => setCanEdit(!canEdit)}
                      >
                        {!canEdit ? (<>Editar imóvel</>) : (<>Cancelar</>)}
                      </Button>
                      {
                        canEdit ? (
                          <>
                            <Button
                              style={{ margin: '0 15px' }}
                              variant="contained"
                              color="warning"
                              onClick={() => handleUpdateDoc(selectedPropertie.id)}
                            >
                              Salvar
                            </Button>
                          </>) : (<></>)
                      }
                    </>
                  ) : (<></>)
                }
              </div>
            </div>

          </Card>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <FloatingWhatsApp avatar={Avatar} chatMessage="Olá, como podemos ajudar?" darkMode allowEsc accountName="Rodrigo Carpenedo imóveis" phoneNumber="65999676622" />
      <Footer />
    </div >
  );
};


export default Home;
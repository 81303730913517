import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/home";
import Properties from "../Pages/Imoveis/imoveis";
import Sobre from "../Pages/Sobre/sobre";
import Login from "../Pages/Login/login";
import Avaliacao from "../Pages/Avaliacao/avaliacao";
import Contato from "../Pages/Contato/contato";

function RoutesApp() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="Imoveis" element={<Properties />} />
      <Route path="Sobre" element={<Sobre />} />
      <Route path="Login" element={<Login />} />
      <Route path="Avaliacao" element={<Avaliacao />} />
      <Route path="Contato" element={<Contato />} />
    </Routes>
  );
}

export default RoutesApp;
